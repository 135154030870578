import classNames from 'classnames';
import React, { createElement, HTMLAttributes } from 'react';

import * as styles from './Text.module.scss';

type TextProps = {
  as?: 'p' | 'span';
  align?: 'left' | 'right' | 'center';
  lead?: boolean;
} & HTMLAttributes<HTMLParagraphElement | HTMLSpanElement>;

const Text = ({ as = 'p', className, align, lead, ...props }: TextProps) => {
  return createElement(as, {
    className: classNames(className, align ? styles[align] : undefined, {[styles.lead]: lead}),
    ...props
  });
};

export default Text;