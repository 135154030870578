import React from "react"

import Layout from '../components/Layout';
import Text from '../components/Text';

import "../app.css";
import ContentSection from "../components/ContentSection";
import DetailList from "../components/DetailList";
import Heading from "../components/Heading";

import markFeaturePhoto from '../images/mark-feature-photo.jpg';

import '../styles/app.scss';

const Index = () => {
  return (
    <Layout>
      <img src={markFeaturePhoto} alt="Mark Stenquist Headshot" width="320" />

      <Text lead>I’m Mark &mdash; a frontend engineer based in Boston, MA. I started my career as a web designer but now I primarily focus on implementing pixel-perfect responsive designs at an agency I co-founded called <a href="https://visualboston.com" target="_blank"><strong>VisualBoston</strong></a>.</Text>

      <ContentSection>
        <Heading as="h4" uppercase>Work</Heading>
        <DetailList items={[
          {id: 1, name: 'VisualBoston', description: 'Co-founder, UX/UI Design & Development', url: 'https://visualboston.com'},
          {id: 2, name: 'Mosaic', description: 'Development, GatsbyJS/React & Contentful', url: 'https://mosaic.us'},
          {id: 3, name: 'Linus Health', description: 'Development, GatsbyJS/React & Contentful', url: 'https://linus.health'},
          {id: 4, name: 'Breaktime Media', description: 'Design + Development, Craft CMS', url: 'https://breaktimemedia.com'}
        ]} />
      </ContentSection>

      <ContentSection>
        <Heading as="h4" uppercase>Hobbies</Heading>
        <DetailList items={[
          {id: 1, name: 'Federal Street Farm', description: 'Organic gardening inspired by Permaculture design', url: 'https://instagram.com/federalstreetfarm'},
          {id: 2, name: 'Loop Grooves', description: 'Guitar loops using my Boss RC-300 loopstation', url: 'https://instagram.com/loopgrooves'},
        ]} />
      </ContentSection>

      {/* <ContentSection>
        <Heading as="h4" uppercase>Social</Heading>
        <DetailList items={[
          {id: 1, name: 'Instagram', description: 'Mostly landscape and gardening pictures', url: 'https://instagram.com/mstenquist'},
          {id: 2, name: 'Twitter', description: 'My digital soapbox', url: 'https://twitter.com/markstenquist'},
          {id: 3, name: 'LinkedIn', description: 'My professional side', url: 'https://linkedin.com/in/markstenquist'},
        ]} />
      </ContentSection> */}
    </Layout>
  )
}

export default Index;

