import React from 'react';

import { CommonIconProps } from '..';

type EnvelopeProps = CommonIconProps;

const Envelope = ({
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '-3 -16 90 90',
}: EnvelopeProps) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9,-9.99999975e-06 C4.0659,-9.99999975e-06 0,4.06599 0,8.99999 L0,49.00002 C0,53.93402 4.0659,58.00002 9,58.00002 L75,58.00002 C79.9341,58.00002 84,53.93402 84,49.00002 L84,8.99999 C84,4.06599 79.9341,-9.99999975e-06 75,-9.99999975e-06 L9,-9.99999975e-06 Z M9.6562,5.99999 L74.375,5.99999 L43.8438,33.25002 C42.6799,34.28922 41.3854,34.29032 40.2188,33.25002 L9.6562,5.99999 Z M6,10.78119 L26.5625,29.12502 L6,48.87502 L6,10.78119 Z M78,10.78119 L78,48.84382 L57.5,29.09382 L78,10.78119 Z M53,33.12502 L72.5938,52.00002 L11.4062,52.00002 L31.0625,33.15622 L36.2188,37.75002 C39.4964,40.67242 44.5658,40.67712 47.8438,37.75002 L53,33.12502 Z'
      fill={fill}
    />
  </svg>
);

export default Envelope;
