import React from 'react';

import {
  ChevronRight,
  Close,
  Contact,
  Envelope,
  Instagram,
  LinkedIn,
  MenuBurger,
  Twitter
} from './IconSvgs';

export type CommonIconProps = {
  style?: React.CSSProperties;
  fill?: string;
  className?: string;
  viewBox?: string;
  width?: string | number;
  height?: string | number;
};

export type IconName =
  | 'chevronRight'
  | 'close'
  | 'contact'
  | 'envelope'
  | 'instagram'
  | 'linkedin'
  | 'menuBurger'
  | 'twitter'
  | 'youtube';

export type IconProps = CommonIconProps & {
  name: IconName;
};

const Icon = (props: IconProps) => {
  switch (props.name) {
    case 'chevronRight':
      return <ChevronRight {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'contact':
      return <Contact {...props} />;
    case 'envelope':
      return <Envelope {...props} />;
    case 'instagram':
      return <Instagram {...props} />;
    case 'linkedin':
      return <LinkedIn {...props} />;
    case 'menuBurger':
      return <MenuBurger {...props} />;
    case 'twitter':
      return <Twitter {...props} />;
    default:
      return <div />;
  }
};

export default Icon;
