import React from 'react';

import * as styles from './ContentSection.module.scss';

interface IContentSection {
  children: React.ReactNode;
}

const ContentSection = ({ children }: IContentSection) => {
  return (
    <section className={styles.contentSection}>
      {children}
    </section>
  )
}

export default ContentSection;
