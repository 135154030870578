import React, { ReactNode } from 'react';
import cn from 'classnames';

import { Link } from 'gatsby';

import * as styles from './Button.module.scss';

interface IButtonProps {
  text: ReactNode;
  adornment?: () => JSX.Element;
  size?: 'large';
  variant?: 'secondary' | 'ghost';
  outline?: boolean;
  className?: string;
  type?: 'submit' | 'button';
  handleClick?: () => void;
  uppercase?: boolean;
  to?: string;
  href?: string;
  disabled?: boolean;
  angled?: boolean;
}

const Button = ({
  adornment,
  className,
  size,
  text,
  type,
  to = '/',
  handleClick,
  disabled,
  href,
  uppercase,
  variant,
  angled,
  outline
}: IButtonProps): JSX.Element => {
  const elementStyles = cn(
    styles.button,
    {
      [styles.buttonLarge]: size === 'large',
      [styles.buttonAngled]: angled,
      [styles.buttonSecondary]: variant === 'secondary',
      [styles.buttonGhost]: variant === 'ghost',
      [styles.buttonCaps]: uppercase,
      [styles.buttonOutline]: outline
    },
    className
  );
  if (href) {
    return (
      <a href={href} target={'_blank'} rel={'noopener noreferrer'} className={elementStyles}>
        {text} {adornment !== undefined && adornment()}
      </a>
    );
  }
  if (href === null) {
    return (
      <button
        type={type}
        disabled={disabled}
        className={elementStyles}
        onClick={handleClick}
      >
        {text} {adornment !== undefined && adornment()}
      </button>
    );
  }
  return (
    <Link to={to} className={elementStyles}>
      {text} {adornment !== undefined && adornment()}
    </Link>
  );
};

export default Button;
