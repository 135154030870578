import React from 'react'
import Icon from '../Icon';

import * as styles from './DetailList.module.scss';

interface IDetailItem {
  name: string;
  description: string;
  url: string;
  id: number;
}

interface IDetailListProps {
  items: IDetailItem[];
}

const DetailList = ({ items }: IDetailListProps) => {
  return (
    <ul className={styles.detailList}>
      {items.map(({ name, description, url, id }) => (
        <li key={id} className={styles.detailListItem}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <div>
              <h3>{name}</h3>
              <p>{description}</p>
            </div>

            <Icon name="chevronRight" fill="var(--dove)" />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default DetailList;
