import React from 'react';

import { CommonIconProps } from '..';

type ICloseProps = CommonIconProps;

const Close = ({
  style = {},
  fill = '#000',
  className = '',
  height = '24px',
  width = '24px',
  viewBox = '0 0 24 24',
}: ICloseProps) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.16316 7.16269C7.38015 6.94575 7.73194 6.94577 7.9489 7.16275L12.0003 11.2144L16.0515 7.16353C16.2685 6.94657 16.6203 6.94657 16.8373 7.16353C17.0542 7.38048 17.0542 7.73224 16.8373 7.9492L12.786 12.0001L16.8373 16.0516C17.0543 16.2686 17.0542 16.6204 16.8372 16.8373C16.6203 17.0543 16.2685 17.0542 16.0515 16.8373L12.0003 12.7858L7.94847 16.8373C7.73149 17.0542 7.37971 17.0542 7.16273 16.8373C6.94576 16.6203 6.94576 16.2686 7.16273 16.0516L11.2146 12.0001L7.16311 7.94837C6.94615 7.73139 6.94617 7.37963 7.16316 7.16269Z'
      fill={fill}
    />
  </svg>
);

export default Close;
