import React from 'react';

import { CommonIconProps } from '..';

const ChevronRight = ({
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '0 0 24 24',
}: CommonIconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox={viewBox}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Desktop" transform="translate(-291.000000, -786.000000)" fill={fill}>
              <g id="Group-2" transform="translate(42.000000, 758.000000)">
                  <g id="Combined-Shape-2" transform="translate(245.014719, 27.964466)">
                      <path d="M11.3284271,22.6923882 C10.9379028,22.3018639 10.9379028,21.6686989 11.3284271,21.2781746 L20.571727,12.036289 L11.3284271,2.79289322 C10.9379028,2.40236893 10.9379028,1.76920395 11.3284271,1.37867966 C11.7189514,0.988155365 12.3521164,0.988155365 12.7426407,1.37867966 L22.6421356,11.2781746 C22.8205833,11.4566222 22.9174904,11.6857327 22.9328569,11.9192058 L22.9337452,12.0360916 C22.9475277,12.3075577 22.8503245,12.5847043 22.6421356,12.7928932 L12.7426407,22.6923882 C12.3521164,23.0829124 11.7189514,23.0829124 11.3284271,22.6923882 Z" id="Path"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
};

export default ChevronRight;