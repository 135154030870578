import React from 'react';

import { CommonIconProps } from '..';

type ContactProps = CommonIconProps;

const Contact = ({
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '-120 -90 600 600',
}: ContactProps) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M98,0 L98,384 L345,384 L345,0 L98,0 Z M221,101 C247,101 268,122 268,148 C268,173 247,194 221,194 C196,194 175,173 175,148 C175,122 196,101 221,101 Z M78,0 L78,384 L51,384 C46,384 41,382 37,378 C33,374 31,369 31,364 L31,364 L31,340 L10,340 C5,340 0,336 0,330 L0,330 L0,310 C0,304 5,299 10,299 L10,299 L31,299 L31,255 L10,255 C5,255 0,250 0,245 L0,245 L0,225 C0,219 5,214 10,214 L10,214 L31,214 L31,170 L10,170 C5,170 0,165 0,160 L0,160 L0,139 C0,134 5,129 10,129 L10,129 L31,129 L31,85 L10,85 C5,85 0,80 0,74 L0,74 L0,54 C0,49 5,44 10,44 L10,44 L31,44 L31,20 C31,15 33,10 37,6 C41,2 46,0 51,0 L51,0 L78,0 Z M221,207 C265,207 300,241 303,283 L140,283 C143,241 178,207 221,207 Z'
      fill={fill}
    />
  </svg>
);

export default Contact;
