import React from 'react';

import { CommonIconProps } from '..';

type IAddProps = CommonIconProps;

const MenuButton = ({
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '0 0 24 24',
}: IAddProps) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='3' width='24' height='2' rx='1' fill={fill} />
    <rect y='11' width='24' height='2' rx='1' fill={fill} />
    <rect y='19' width='24' height='2' rx='1' fill={fill} />
  </svg>
);

export default MenuButton;
