import React from 'react'
import { Mobile } from '../../ui/responsive';
import Button from '../Button';

import * as styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Mobile>
        <div>
          <Button text="View on LinkedIn" href="https://www.linkedin.com/in/markstenquist/" />
        </div>
      </Mobile>
      &copy; 2021 - Mark Stenquist
    </footer>
  )
}

export default Footer
