import React from 'react';
import MediaQuery, { MediaQueryProps, useMediaQuery } from 'react-responsive';

import { mediaQueries } from './view';

export const Default = ({ children }: MediaQueryProps) => (
  <MediaQuery minWidth={mediaQueries.smallMax + 1}>{children}</MediaQuery>
);

// Mobile

export const Mobile = ({ children }: MediaQueryProps) => (
  <MediaQuery maxWidth={mediaQueries.smallMax}>{children}</MediaQuery>
);

// Tablet

export const Tablet = ({ children }: MediaQueryProps) => (
  <MediaQuery
    minWidth={mediaQueries.mediumMax}
    maxWidth={mediaQueries.largeMin}
  >
    {children}
  </MediaQuery>
);

// Tablet Down

export const TabletDown = ({ children }: MediaQueryProps) => (
  <MediaQuery maxWidth={mediaQueries.mediumMax}>{children}</MediaQuery>
);

// Desktop

export const useIsDesktop = () =>
  useMediaQuery({ minWidth: mediaQueries.largeMin });

export const Desktop = ({ children }: MediaQueryProps) => (
  <MediaQuery minWidth={mediaQueries.largeMin}>{children}</MediaQuery>
);

// Desktop Large

export const useIsDesktopLarge = () =>
  useMediaQuery({ minWidth: mediaQueries.xlargeMin });

export const DesktopLarge = ({ children }: MediaQueryProps) => (
  <MediaQuery minWidth={mediaQueries.xlargeMin}>{children}</MediaQuery>
);
