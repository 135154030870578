import React, { useState } from 'react'
import { Link } from 'gatsby';

import Button from '../Button';
import Container from '../Container';
import MenuButton from '../MenuButton';
import Sidebar from '../Sidebar';

import * as styles from './Header.module.scss';
import { Desktop } from '../../ui/responsive';

const Header = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  return (
    <>
      <header className={styles.header}>
        <Container className={styles.headerInner}>
          {/* <MenuButton /> */}
          <Link to="/" className={styles.headerLogo}>
            Mark Stenquist
          </Link>
          <Desktop>
            <Button text="View on LinkedIn" href="https://www.linkedin.com/in/markstenquist/" />
          </Desktop>
        </Container>
      </header>
      <Sidebar show={sidebarIsOpen} />
    </>
  )
}

export default Header

