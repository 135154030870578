import classNames from 'classnames';
import React, { createElement, HTMLAttributes } from 'react';

import * as styles from './Heading.module.scss';

type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  align?: 'left' | 'right' | 'center';
  uppercase?: boolean;
} & HTMLAttributes<HTMLHeadingElement>;

const Heading = ({ as = 'h1', className, align, uppercase, ...props }: HeadingProps) => {
  return createElement(as, {
    className: classNames(className, align ? styles[align] : undefined, {[styles.uppercase]: uppercase}),
    ...props
  });
};

export default Heading;