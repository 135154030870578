import React, { useRef } from "react";
import cn from 'classnames';

import * as styles from "./Sidebar.module.scss";

interface ISidebarProps {
  show: boolean;
}

const Sidebar = ({
  show
}:ISidebarProps) => {
  return (
    <menu className={cn(styles.sidebar, {[styles.sidebarShow]: show})}>
      <h3 className={styles.sidebarHeadline}>Sidebar Menu</h3>
    </menu>
  )
};

export default Sidebar;