import React, { ReactNode } from 'react';

import {Helmet} from 'react-helmet';

import Footer from '../Footer';
import Header from '../Header';
import Container from '../Container';

import * as styles from './Layout.module.scss';

import favicon from '../../images/favicon.ico';

const Layout = ({ children }: any) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <meta charSet="utf-8" />
        <title>Mark Stenquist - Frontend Engineer, Designer and Co founder of VisualBoston</title>
        <link rel="canonical" href="http://markstenquist.com" />
      </Helmet>
      <Header />
      <main className={styles.main}>
        <Container>
          {children}
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default Layout;